<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="machine.name"
          :text="machine.serialVendingNumber"
          color="green"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.date }}</td>
              <td>{{ item.typeId }}</td>
              <td>{{ getEventName(item.typeId) }}</td>
              <td>{{ item.metaInfo }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        sortable: true,
        text: 'Время события',
        value: 'date'
      },
      {
        sortable: true,
        text: 'Тип события',
        value: 'typeId'
      },
      {
        sortable: true,
        text: 'Событие',
        value: 'typeId'
      },
      {
        sortable: true,
        text: 'Данные события',
        value: 'metaInfo'
      }
    ],
    items: [ ],
    machine: null
  }),
  created () {
    this.id = this.$route.params.id
  },
  mounted () {
    this.getEvents()
    this.getVendingMachine()
  },
  methods: {
    getEvents () {
      this.$http.get('/vendingmachines/' + this.id + '/events')
        .then(response => {
          this.items = response.data
        })
    },
    getVendingMachine () {
      this.$http.get('/vendingmachines/' + this.id)
        .then(response => {
          this.machine = response.data
        })
    },
    getEventName (eventId) {
      switch (eventId) {
        case 1:
          return 'Включение'
        case 2:
          return 'Выключение'
        case 3:
          return 'Номер последнего патча'
        case 5:
          return 'Команда с сервера'
        case 6:
          return 'Начата загрузка обновления прошивки с сервера'
        case 7:
          return 'Загрузка обновления прошивки с сервера успешно завершена'
        case 8:
          return 'Ошибка загрузка обновления прошивки с сервера'
        case 11:
          return 'Синхронизация времени'
        case 30:
          return 'Подключение к WiFi'
        case 31:
          return 'Отключение от WiFi'
        default:
          return 'Неизвестно'
      }
    }
  }
}
</script>
